import {
  Card,
  CardContent,
  Container,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import SoundSegLogo from '../components/SoundSegLogo';
import SubmitAudioForm from '../components/SubmitAudioForm';

const Home = () => {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  return (
    <Container sx={{ paddingTop: '3rem', paddingBottom: '3rem' }}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item>
          <SoundSegLogo
            logoWidth={mdDown ? '5rem' : '10rem'}
            fontSize={mdDown ? '2.5rem' : '4rem'}
          />
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography>
                Welcome to SoundSeg! This is a tool for segmenting and analyzing
                sound data. If you enter a YouTube URL for a song, the tool will
                convert the video into an audio file and seperate its
                components.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <SubmitAudioForm />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
