import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  ReactNode,
  ReactElement,
  useEffect,
} from 'react';
import ReactDOM from 'react-dom';
import { Box, IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ModalContextType {
  queueModal: (
    modalContent: ReactElement,
    id: string,
    position?: 'append' | 'prepend'
  ) => void;
  closeModal: (id: string) => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const useModal = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

interface ModalProviderProps {
  children: ReactNode;
}

interface ModalQueueItem {
  id: string;
  content: ReactElement;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [modalQueue, setModalQueue] = useState<ModalQueueItem[]>([]);
  const [currentModal, setCurrentModal] = useState<ModalQueueItem | null>(null);

  const queueModal = useCallback(
    (
      modalContent: ReactElement,
      id: string,
      position: 'append' | 'prepend' = 'append'
    ) => {
      setModalQueue((prevQueue) => {
        const newModal: ModalQueueItem = { id, content: modalContent };
        if (position === 'prepend') {
          return [newModal, ...prevQueue];
        }
        return [...prevQueue, newModal];
      });
    },
    []
  );

  const closeModal = useCallback((id: string) => {
    setModalQueue((prevQueue) => prevQueue.filter((modal) => modal.id !== id));
  }, []);

  useEffect(() => {
    if (modalQueue.length > 0 && !currentModal) {
      setCurrentModal(modalQueue[0]);
    } else if (modalQueue.length === 0 && currentModal) {
      setCurrentModal(null);
    }
  }, [modalQueue, currentModal]);

  useEffect(() => {
    if (
      currentModal &&
      !modalQueue.find((modal) => modal.id === currentModal.id)
    ) {
      setCurrentModal(null);
    }
  }, [modalQueue, currentModal]);

  return (
    <ModalContext.Provider value={{ queueModal, closeModal }}>
      {children}
      {currentModal &&
        ReactDOM.createPortal(
          <Modal open={true} onClose={() => closeModal(currentModal.id)}>
            <Box
              sx={{
                position: 'relative',
                width: 400,
                bgcolor: 'background.paper',
                p: 4,
                m: 'auto',
                mt: '10%',
                borderRadius: 2,
              }}
            >
              <IconButton
                onClick={() => closeModal(currentModal.id)}
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
              {currentModal.content}
            </Box>
          </Modal>,
          document.body
        )}
    </ModalContext.Provider>
  );
};
