import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  List,
  TextField,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useAuth } from '../context/AuthContext';
import { useModal } from '../context/ModalContext';
import GoogleSignInButton from '../components/GoogleSignInButton';
import SoundSegLogo from './SoundSegLogo';
import { useState } from 'react';
import AudioResponseListItem from './AudioResponseListItem';
import {
  AudioConversionRequestForm,
  AudioConversionResponseForm,
  WebResponse,
} from '../constants/api';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getApp } from 'firebase/app';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

const LOGIN_MODAL_ID = 'login';

const youtubeUrlPattern =
  /^(http(s)?:\/\/)?((w){3}.)?(m.)?youtu(be|.be)?(\.com)?\/.+/;

const getHelperText = (error: any) => {
  if (!error) return '';
  switch (error.type) {
    case 'required':
      return 'YouTube URL is required';
    case 'pattern':
      return 'Invalid YouTube URL';
    default:
      return error.message;
  }
};

const SubmitAudioForm = () => {
  const { user } = useAuth();
  const { queueModal, closeModal } = useModal();
  const { register, handleSubmit, formState, setError } =
    useForm<AudioConversionRequestForm>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseData, setResponseData] = useState<
    AudioConversionResponseForm[]
  >([]);
  const areFilesReady = !!responseData?.length;

  const functions = getFunctions(getApp());
  const segment = httpsCallable<
    AudioConversionRequestForm,
    WebResponse<AudioConversionResponseForm[]>
  >(functions, 'segement');
  const storage = getStorage();

  const onAuthenticatedSubmit = async (data: AudioConversionRequestForm) => {
    setIsSubmitting(true);
    setResponseData([]);
    const res = await segment(data);
    if (res.data.success) {
      var resData = res.data.data;
      // for each url in the array, we need to get the download URL from Firebase Storage
      for (let i = 0; i < resData?.length; i++) {
        const storageRef = ref(storage, resData[i].url);
        resData[i].url = await getDownloadURL(storageRef);
      }
      setResponseData(resData);
    } else {
      setError('youtubeUrl', { type: 'manual', message: "Youtube is blocking our requests. We are working on a fix." });
    }

    setIsSubmitting(false);
  };

  const onSubmit = (data: AudioConversionRequestForm) => {
    if (!user) {
      queueModal(
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item>
            <SoundSegLogo logoWidth="6rem" fontSize="2rem" />
          </Grid>
          <Grid item>
            <Typography variant="h5" align="center">
              Please sign in to use this feature
            </Typography>
          </Grid>

          <Grid item>
            <GoogleSignInButton
              onSuccess={() => {
                onAuthenticatedSubmit(data);
                closeModal(LOGIN_MODAL_ID);
              }}
            />
          </Grid>
        </Grid>,
        LOGIN_MODAL_ID
      );
    } else {
      onAuthenticatedSubmit(data);
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h6">
              Input a valid YouTube URL to get started
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="https://www.youtube.com/watch?v=..."
                sx={{ marginTop: '1rem' }}
                error={!!formState.errors.youtubeUrl}
                helperText={getHelperText(formState.errors.youtubeUrl)}
                {...register('youtubeUrl', {
                  required: true,
                  pattern: youtubeUrlPattern,
                })}
              />

              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ marginTop: '1rem' }}
                disabled={isSubmitting}
              >
                Submit
              </Button>
            </form>
          </CardContent>
        </Card>
      </Grid>

      {isSubmitting && (
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid
                container
                alignItems="center"
                direction="column"
                spacing={3}
              >
                <Grid item>
                  <Typography align="center">
                    This can take up to a minute to complete...
                  </Typography>
                </Grid>
                <Grid item>
                  <CircularProgress />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}

      {areFilesReady && (
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Your file{responseData?.length > 1 ? 's are' : ' is'} ready for
                download:
              </Typography>
              <List>
                {responseData?.map(
                  (item: AudioConversionResponseForm, index: number) => (
                    <AudioResponseListItem key={index} {...item} />
                  )
                )}
              </List>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export default SubmitAudioForm;
