import Button from '@mui/material/Button';
import { useAuth } from '../context/AuthContext';
import googleIcon from '../assets/web_neutral_rd_na.svg';
import { User } from 'firebase/auth';

const GoogleLogoIcon = () => (
  <img
    src={googleIcon}
    alt="Google Logo"
    style={{ width: '24px', height: '24px' }}
  />
);

interface Props {
  onSuccess?: (user: User) => void;
}

const GoogleSignInButton: React.FC<Props> = ({ onSuccess }) => {
  const { signInWithGoogle } = useAuth();

  return (
    <Button
      variant="contained"
      startIcon={<GoogleLogoIcon />}
      onClick={() => signInWithGoogle(onSuccess)}
      sx={{
        backgroundColor: '#F2F2F2',
        color: '#1F1F1F',
        textTransform: 'none',
        transition: '0.2s',
        borderRadius: '10rem',
        '&:hover': {
          backgroundColor: '#F2F2F2',
          transition: '0.2s',
          opacity: 0.8,
        },
      }}
    >
      Sign in with Google
    </Button>
  );
};

export default GoogleSignInButton;
