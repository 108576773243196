import { Grid, Typography } from '@mui/material';
import soundSegLogo from '../assets/sound_seg_logo.png';

interface Props {
  logoWidth?: string;
  fontSize?: string;
}

const SoundSegLogo: React.FC<Props> = ({
  logoWidth = '3rem',
  fontSize = '1rem',
}) => {
  return (
    <Grid container direction="row" alignItems="center" spacing={1}>
      <Grid item>
        <img
          src={soundSegLogo}
          alt="Sound Seg Logo"
          style={{ width: logoWidth }}
        />
      </Grid>
      <Grid item>
        <Typography
          sx={{
            fontSize,
            fontWeight: 'bold',
            backgroundImage:
              'linear-gradient(to bottom, #007FFF, #89D4CF, #FFFFFF)',
            WebkitBackgroundClip: 'text',
            backgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            color: 'transparent',
          }}
        >
          SoundSeg
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SoundSegLogo;
