import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
  typography: {
    fontFamily: 'Montserrat',
  },
  components: {
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '1.5rem',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '0.75rem',
          fontWeight: 600,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0 0 1rem rgba(0, 0, 0, 0.4)',
          borderRadius: '0.75rem',
        },
      },
    },
  },
});

export default theme;
