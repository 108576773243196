import { createContext, useContext, useEffect, useState } from 'react';
import { User, signInWithPopup, signOut } from 'firebase/auth';
import { auth, provider } from '../firebase';

interface AuthContextType {
  user: User | undefined;
  signInWithGoogle: (onSuccess?: (user: User) => void) => Promise<void>;
  signOutUser: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType>({
  user: undefined,
  signInWithGoogle: async () => {},
  signOutUser: async () => {},
});

interface Props {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<User>();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(undefined);
      }
    });
    return () => unsubscribe();
  }, []);

  const signInWithGoogle = async (onSuccess?: (user: User) => void) => {
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      onSuccess?.(result.user);
    } catch (error) {
      console.error('Error during sign-in:', error);
    }
  };

  const signOutUser = async () => {
    try {
      await signOut(auth);
      setUser(undefined);
    } catch (error) {
      console.error('Error during sign-out:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, signInWithGoogle, signOutUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
