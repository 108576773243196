import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './constants/theme';
import Home from './pages/Home';
import Header from './components/Header';
import { AuthProvider } from './context/AuthContext';
import { ModalProvider } from './context/ModalContext';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <ModalProvider>
          <CssBaseline />
          <Header />
          <Home />
        </ModalProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
