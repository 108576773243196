import { AppBar, Button, Container, Grid } from '@mui/material';
import GoogleSignInButton from './GoogleSignInButton';
import { useAuth } from '../context/AuthContext';
import SoundSegLogo from './SoundSegLogo';

const Header = () => {
  const { user, signOutUser } = useAuth();

  return (
    <AppBar
      style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
      position="static"
    >
      <Container maxWidth="xl">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <SoundSegLogo />
          </Grid>

          <Grid item>
            {user ? (
              <Button variant="contained" onClick={signOutUser}>
                Sign Out
              </Button>
            ) : (
              <GoogleSignInButton />
            )}
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  );
};

export default Header;
